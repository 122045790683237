import * as React from 'react';
import {
  faFacebookF as facebookIcon,
  faInstagram as instagramIcon,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialIcons = (props: React.HTMLAttributes<HTMLElement>) => (
  <>
    <a
      target="_blank"
      href={'https://www.instagram.com/holzbau_kroeplien/?hl=de'}
    >
      <FontAwesomeIcon icon={instagramIcon} />
    </a>
    <a target="_blank" href={'https://www.facebook.com/HolzBauKroeplien'}>
      <FontAwesomeIcon icon={facebookIcon} />
    </a>
    <span>holzbau_kroeplien</span>
  </>
);

export default SocialIcons;
