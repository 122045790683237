import * as React from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface ModalProps extends React.PropsWithChildren {
  isInitiallyOpen: boolean;
  title: string;
  description?: string;
}

const Modal = ({ isInitiallyOpen, title, children }: ModalProps) => {
  const [isOpen, setIsOpen] = React.useState(isInitiallyOpen);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const scrollToTop = () => {
    modalRef.current?.scrollTo({ top: 0, behavior: 'auto' });
  };
  return (
    <>
      <button className="text-right" type="button" onClick={openModal}>
        {title}
      </button>

      <Transition show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto" ref={modalRef}>
            <div className="flex min-h-full items-center justify-center p-8 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                beforeEnter={scrollToTop}
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium text-brand-brown"
                  >
                    {title}
                  </Dialog.Title>

                  {/*<Dialog.Description className="mt-2">*/}
                  {/*  {description}*/}
                  {/*</Dialog.Description>*/}

                  <div className="mt-2">{children}</div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-brand-yellow px-4 py-2 text-sm font-medium text-white hover:bg-brand-yellow/90 focus:outline-none"
                      onClick={closeModal}
                    >
                      Schließen
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
