// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faFacebookF as facebookIcon,
//   faInstagram as instagramIcon,
// } from '@fortawesome/free-brands-svg-icons';
import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';
import { Obfuscate } from '@south-paw/react-obfuscate-ts';
import Handwerk from '../components/Handwerk';
import Logo from '../components/Logo';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import LogoFooter from '../components/LogoFooter';
import Modal from '../components/Modal';
import SocialIcons from '../components/SocialIcons';
// import Logo from '../images/hbk.svg'

// markup
const IndexPage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  const image = getImage(data.teaserImage as ImageDataLike) as IGatsbyImageData;
  const contactImage = getImage(
    data.contactImage as ImageDataLike,
  ) as IGatsbyImageData;
  const contactBgImage = convertToBgImage(contactImage);
  const teamImages = data.teamImages.nodes.map<IGatsbyImageData>(
    (node) => getImage(node as ImageDataLike) as IGatsbyImageData,
  );
  const galleries = data.galleryImages.nodes.reduce((galleries, img) => {
    // console.log(img.);
    const key = img.relativeDirectory.replace('gallery/', '');
    const image = getImage(img as ImageDataLike) as IGatsbyImageData;
    if (typeof galleries[key] === 'undefined') {
      galleries[key] = { title: key, teaser: key, images: [image] };
    } else {
      galleries[key].images.push(image);
    }
    return galleries;
  }, {} as Record<string, { title: string; teaser: string; images: IGatsbyImageData[] }>);
  // console.log(data, teamImages, galleries);
  return (
    <main>
      <title>Holzbau Kröplien</title>
      <div className={'flex flex-col bg-brand-cyan md:flex-row'}>
        <div className={'md:w-2/3'}>
          <div className={'flex h-16 items-center bg-white p-4'}>
            <Logo className={'h-8'} />
          </div>
          <GatsbyImage
            image={image}
            alt={'Teaser'}
            className={'object-cover'}
          />
        </div>
        <div className={'flex flex-col md:w-1/3'}>
          <div className={'flex grow items-center justify-center'}>
            <div className={'p-8 text-white md:w-2/3 md:p-4'}>
              <Handwerk
                className={'mb-6 w-full border-b-4 fill-current pb-6'}
              />
              <p className={'font-medium'}>
                In allen 4 Wänden und auch auf jedem Grundstück steckt
                Potential. Wir besitzen nicht nur das Know How, sondern es ist
                auch unsere Leiden&shy;schaft ihr Eigentum wert&shy;schöpfend
                aus-/ um-/ fertigzubauen, gänzlich zu erneuern oder sogar ganz
                neues Ideen als Projekte gemeinsam mit Ihnen zu realisieren.
              </p>
            </div>
          </div>
          <div
            className={
              'flex-nowrap space-x-2 self-end rounded-l-full bg-white py-2 pl-3 pr-4 text-brand-cyan'
            }
          >
            <SocialIcons />
          </div>
        </div>
      </div>

      <div className={'flex flex-col bg-brand-cyan text-white md:flex-row'}>
        <div className={'m-8 md:m-12 md:w-2/5'}>
          <h1
            className={
              'mb-6 inline-block border-b-4 pb-6 text-4xl uppercase md:text-7xl'
            }
          >
            Über uns
          </h1>
          <p className={'font-medium'}>
            Wir sind eine mittelständische, kleine Zimmer&shy;manns&shy;firma,
            die sich auf den Raum Nord&shy;west&shy;mecklen&shy;burg fokussiert
            hat. Ich, Stefan Kröplien, bin vor gar nicht allzu langer Zeit in
            die Fuß&shy;stapfen meines Vaters getreten und habe dieses
            Unternehmen von ihm übernommen. In den letzten Jahren haben wir
            nicht nur unsern Fuhrpark aufrüsten können, sondern auch das Team.
            Ich beschäftige 6 Mitarbeiter, deren Aufgabengebiete sehr breit
            gefächert sind. Wir bedienen vom klassischen Holzbau bis zu
            Komplett-Sanierungen von Bestandsgebäuden nahezu alles.
          </p>
          <p></p>
        </div>
        <div className={'mb-8 md:my-12 md:grow'}>
          <div
            className={
              'mb-8 flex justify-between gap-2 md:mb-12 md:mr-12 md:gap-8'
            }
          >
            {teamImages.map((image, i) => (
              <GatsbyImage
                key={i}
                image={image}
                alt={'Teaser'}
                className={'w-1/3'}
              />
            ))}
          </div>
          <div
            className={
              'relative flex flex-col space-y-4 px-8 before:absolute before:mx-3 before:h-full before:w-1 before:bg-white md:flex-row md:space-x-4 md:space-y-0 md:px-0 md:before:mx-0 md:before:my-3 md:before:h-1 md:before:w-full'
            }
          >
            {/* https://lab.ishadeed.com/container-queries/timeline/ */}
            {data.site?.siteMetadata?.timeline?.map((text, i) => (
              <div
                key={i}
                className={
                  'flex items-center space-x-2 md:flex-col md:space-x-0 md:space-y-4'
                }
              >
                <div
                  className={
                    'ml-0.5 h-6 w-6 shrink-0 rounded-full bg-white md:ml-0 md:mt-0.5'
                  }
                >
                  {' '}
                </div>
                <div className={'shrink text-sm md:text-center'}>{text}</div>
              </div>
            ))}
            <div className={'hidden w-12 md:block'}> </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8 md:m-8 md:grid-cols-4">
        <div className={'m-8 md:col-span-2 md:m-0'}>
          <h1
            className={
              'mb-6 table-caption border-b-4 border-brand-yellow pb-6 text-4xl uppercase text-brand-yellow md:text-7xl'
            }
          >
            Unsere Leistungen
          </h1>
          <p className={'w-4/5 font-medium text-brand-brown'}>
            Hier finden Sie einen kleinen, groben Überblick unserer
            Dienstleistungen. Wir arbeiten sehr individuell und auf unseren
            Kunden abgestimmt. Es gibt nichts, was nicht möglich ist, also
            zögern Sie nicht, um uns zu kontaktieren. Egal für welches Anliegen,
            wir finden nicht irgendeine Lösung — Nein! Wir finden Ihre Lösung!
          </p>
        </div>
        {data.site?.siteMetadata?.galleries?.map((gallery) => (
          <a
            key={gallery?.key}
            href={`#gallery-${gallery?.key}`}
            className={'group bg-brand-yellow p-8 text-center text-white'}
          >
            <GatsbyImage
              image={galleries[gallery?.key as string].images[0]}
              alt={'Teaser'}
              className={'aspect-square object-cover'}
            />
            <h2 className={'my-4 text-xl font-bold uppercase'}>
              {gallery?.title}
            </h2>
            <div className={'group-hover:underline'}>Mehr!</div>
          </a>
        ))}
      </div>

      <BackgroundImage
        Tag={'div'}
        {...contactBgImage}
        preserveStackingContext
        className={'my-8 flex flex-col'}
      >
        <div
          className={
            'flex flex-col items-center justify-end self-end bg-brand-cyan p-8 text-white md:w-2/3 md:flex-row md:rounded-l-full md:p-12'
          }
        >
          <div className={'md:order-2 md:w-1/2 md:pl-8'}>
            <h1
              className={
                'mb-6 inline-block border-b-4 pb-6 text-4xl uppercase md:text-7xl'
              }
            >
              Kontakt
            </h1>
            <div className={'text-lg'}>
              <div className={'font-bold'}>Holzbau Kröplien</div>
              <div>Inh. Stefan Kröplien</div>
              <div>Boienhagen 10</div>
              <div>23936 Upahl</div>
              <div className={'mt-4'}>
                <span className={'block md:inline'}>E-Mail:</span>{' '}
                <Obfuscate email>info@holzbau-kroeplien.de</Obfuscate>
              </div>
              <div className={'mb-4'}>
                <span className={'block md:inline'}>Mobil:</span>{' '}
                <Obfuscate tel="+491751861318">0175 18 613 18</Obfuscate>
              </div>
              <div className={'font-medium'}>
                Sie möchten eine bauliche Veränderung? Dann kontaktieren Sie uns
                gerne bevorzugt per E-Mail.
              </div>
              {/*<div className={'font-medium'}>*/}
              {/*  Sie möchten eine bauliche Veränderung? Dann kontaktieren Sie uns*/}
              {/*  gerne bevorzugt per E-Mail oder über dieses Kontaktfomular.*/}
              {/*</div>*/}
            </div>
          </div>
          {/*<div*/}
          {/*  className={*/}
          {/*    'flex flex-col space-y-2 md:order-1 md:w-1/2 md:pl-16 md:pr-8'*/}
          {/*  }*/}
          {/*>*/}
          {/*  <label className="block">*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className={*/}
          {/*        'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'*/}
          {/*      }*/}
          {/*      placeholder={''}*/}
          {/*    />*/}
          {/*    <span className={'text-sm'}>Name, Vorname</span>*/}
          {/*  </label>*/}
          {/*  <label className="block">*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className={*/}
          {/*        'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'*/}
          {/*      }*/}
          {/*      placeholder={''}*/}
          {/*    />*/}
          {/*    <span className={'text-sm'}>E-Mail</span>*/}
          {/*  </label>*/}
          {/*  <label className="block">*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className={*/}
          {/*        'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'*/}
          {/*      }*/}
          {/*      placeholder={''}*/}
          {/*    />*/}
          {/*    <span className={'text-sm'}>Telefonnummer</span>*/}
          {/*  </label>*/}
          {/*  <label className="block">*/}
          {/*    <textarea*/}
          {/*      rows={2}*/}
          {/*      className={*/}
          {/*        'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'*/}
          {/*      }*/}
          {/*      placeholder={''}*/}
          {/*    />*/}
          {/*    <span className={'text-sm'}>Nachricht</span>*/}
          {/*  </label>*/}
          {/*  <label className="inline-flex items-center">*/}
          {/*    <input*/}
          {/*      type="checkbox"*/}
          {/*      className={*/}
          {/*        'focus:brand-brown rounded-full border-0 text-brand-brown focus:ring-0'*/}
          {/*      }*/}
          {/*    />*/}
          {/*    <span className="ml-2 text-xs">*/}
          {/*      Mit dem Absenden stimme ich den*/}
          {/*      Daten&shy;schutz&shy;bestimmungen zu.*/}
          {/*    </span>*/}
          {/*  </label>*/}
          {/*  <input*/}
          {/*    type="submit"*/}
          {/*    className={*/}
          {/*      'self-end rounded-2xl bg-white px-3 py-2 text-lg font-medium text-brand-cyan'*/}
          {/*    }*/}
          {/*    value="Absenden"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </BackgroundImage>

      <div className={'m-8 md:m-20'}>
        <h1
          className={
            'mb-6 inline-block border-b-4 border-brand-yellow pb-6 text-4xl uppercase text-brand-yellow md:text-7xl'
          }
        >
          Gallerie
        </h1>
        <p className={'font-medium text-brand-brown'}>
          Wir geben Ihnen gerne einen Einblick in unseren Alltag rund ums
          Handwerk und Haus. Auch Sie könnten bald einen Teil dieser Gallerie
          füllen.
        </p>
      </div>
      {data.site?.siteMetadata?.galleries?.map((gallery, i) => (
        <div
          key={gallery?.key}
          id={`gallery-${gallery?.key}`}
          className={'mb-4 flex flex-col md:mb-8 md:flex-row md:space-x-24'}
        >
          <div
            className={
              'sticky top-0 z-10 flex items-center bg-brand-yellow py-4 pl-8 text-2xl font-medium uppercase text-white md:h-24 md:w-1/3 md:rounded-r-full md:pl-20 md:text-3xl'
            }
          >
            {gallery?.title}
          </div>
          <div className={'mt-4 grow md:mt-0 md:pr-24'}>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8">
              {galleries[gallery?.key as string].images.map((image, i) => (
                <GatsbyImage
                  key={i}
                  image={image}
                  alt={'Teaser'}
                  className={'object-cover'}
                />
              ))}
            </div>
          </div>
        </div>
      ))}

      <footer
        className={
          'm-8 flex flex-col space-y-6 text-brand-brown md:flex-row md:space-y-0 md:space-x-8'
        }
      >
        <div className={'mt-8 flex justify-center'}>
          <LogoFooter className={'w-full max-w-xs'} />
        </div>
        <div
          className={
            'flex grow flex-col items-center justify-center space-x-2 text-center md:flex-row md:self-end'
          }
        >
          <div>Na? Neugierig geworden?</div>
          <div className={'space-x-2'}>
            <SocialIcons />
          </div>
        </div>
        <div className={'flex flex-col text-center md:self-end md:text-left'}>
          <Modal isInitiallyOpen={false} title={'Impressum'}>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              Angaben gemäß § 5 TMG
            </h4>
            <p>
              Stefan Kröplien
              <br />
              Holzbau Kröplien
              <br />
              Boienhagen 10
              <br />
              23936 Upahl
            </p>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              Kontakt
            </h4>
            <div>
              <span className={'block md:inline'}>E-Mail:</span>{' '}
              <Obfuscate email>info@holzbau-kroeplien.de</Obfuscate>
            </div>
            <div>
              <span className={'block md:inline'}>Mobil:</span>{' '}
              <Obfuscate tel="+491751861318">0175 18 613 18</Obfuscate>
            </div>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
            </h4>
            <p>
              Wir sind nicht verpflichtet, an Streitbeilegungsverfahren vor
              einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
          </Modal>
          <Modal isInitiallyOpen={false} title={'Datenschutz'}>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              1. Datenschutz auf einen Blick
            </h4>
            <h5 className="my-2 font-medium text-brand-brown">
              Allgemeine Hinweise
            </h5>{' '}
            <p>
              Die folgenden Hinweise geben einen einfachen &Uuml;berblick
              dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn
              Sie diese Website besuchen. Personenbezogene Daten sind alle
              Daten, mit denen Sie pers&ouml;nlich identifiziert werden
              k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz
              entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
              Datenschutzerkl&auml;rung.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Datenerfassung auf dieser Website
            </h5>{' '}
            <h6 className="my-2 text-sm font-medium text-brand-brown">
              Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
              Website?
            </h6>{' '}
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
              Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in
              dieser Datenschutzerkl&auml;rung entnehmen.
            </p>{' '}
            <h6 className="my-2 text-sm font-medium text-brand-brown">
              Wie erfassen wir Ihre Daten?
            </h6>{' '}
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
              Sie in ein Kontaktformular eingeben.
            </p>{' '}
            <p>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem
              oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
              erfolgt automatisch, sobald Sie diese Website betreten.
            </p>{' '}
            <h6 className="my-2 text-sm font-medium text-brand-brown">
              Wof&uuml;r nutzen wir Ihre Daten?
            </h6>{' '}
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
              k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>{' '}
            <h6 className="my-2 text-sm font-medium text-brand-brown">
              Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
            </h6>{' '}
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
              Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
              Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
              verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
              erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
              f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
              Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
              Aufsichtsbeh&ouml;rde zu.
            </p>{' '}
            <p>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen
              Sie sich jederzeit an uns wenden.
            </p>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              2. Hosting
            </h4>
            <p>
              Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
            </p>
            <h5 className="my-2 font-medium text-brand-brown">Hetzner</h5>{' '}
            <p>
              Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710
              Gunzenhausen (nachfolgend Hetzner).
            </p>{' '}
            <p>
              Details entnehmen Sie der Datenschutzerkl&auml;rung von Hetzner:{' '}
              <a
                href="https://www.hetzner.com/de/rechtliches/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hetzner.com/de/rechtliches/datenschutz
              </a>
              .
            </p>{' '}
            <p>
              Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
              m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website.
              Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
              die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
              Einwilligung die Speicherung von Cookies oder den Zugriff auf
              Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
              Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <h6 className="my-2 text-sm font-medium text-brand-brown">
              Auftragsverarbeitung
            </h6>{' '}
            <p>
              Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) mit
              dem oben genannten Anbieter geschlossen. Hierbei handelt es sich
              um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
              gew&auml;hrleistet, dass dieser die personenbezogenen Daten
              unserer Websitebesucher nur nach unseren Weisungen und unter
              Einhaltung der DSGVO verarbeitet.
            </p>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              3. Allgemeine Hinweise und Pflicht&shy;informationen
            </h4>
            <h5 className="my-2 font-medium text-brand-brown">Datenschutz</h5>{' '}
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer
              pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend den
              gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerkl&auml;rung.
            </p>{' '}
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
              Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche
              Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
              erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
            </p>{' '}
            <p>
              Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
              (z.&nbsp;B. bei der Kommunikation per E-Mail)
              Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Hinweis zur verantwortlichen Stelle
            </h5>{' '}
            <p>
              Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
              dieser Website ist:
            </p>{' '}
            <p>
              Stefan Kr&ouml;plien
              <br />
              Holzbau Kr&ouml;plien
              <br />
              Boienhagen 10
              <br />
              23936 Upahl
            </p>
            <div>
              <span className={'block md:inline'}>E-Mail:</span>{' '}
              <Obfuscate email>info@holzbau-kroeplien.de</Obfuscate>
            </div>
            <div>
              <span className={'block md:inline'}>Mobil:</span>{' '}
              <Obfuscate tel="+491751861318">0175 18 613 18</Obfuscate>
            </div>
            <p>
              Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
              Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten
              (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">Speicherdauer</h5>{' '}
            <p>
              Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
              speziellere Speicherdauer genannt wurde, verbleiben Ihre
              personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
              Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
              L&ouml;schersuchen geltend machen oder eine Einwilligung zur
              Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
              sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde
              f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
              (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
              im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall
              dieser Gr&uuml;nde.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
              auf dieser Website
            </h5>{' '}
            <p>
              Sofern Sie in die Datenverarbeitung eingewilligt haben,
              verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern
              besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet
              werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die
              &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt
              die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49
              Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
              oder in den Zugriff auf Informationen in Ihr Endger&auml;t
              (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt
              die Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25
              Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind
              Ihre Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir
              Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
              Weiteren verarbeiten wir Ihre Daten, sofern diese zur
              Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind
              auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
              Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
              Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
              jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in
              den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung
              informiert.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </h5>{' '}
            <p>
              Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
              ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
              eine bereits erteilte Einwilligung jederzeit widerrufen. Die
              Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
              Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Widerspruchsrecht gegen die Datenerhebung in besonderen
              F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h5>{' '}
            <p>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
              GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
              GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
              EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
              GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
              DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
              DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
              WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
              VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
              SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
              &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
              AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
              (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
            </p>{' '}
            <p>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Beschwerde&shy;recht bei der zust&auml;ndigen
              Aufsichts&shy;beh&ouml;rde
            </h5>{' '}
            <p>
              Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
              Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
              insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
              Aufenthalts, ihres Arbeitsplatzes oder des Orts des
              mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
              unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
            </h5>{' '}
            <p>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen,
              maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie
              die direkte &Uuml;bertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Auskunft, L&ouml;schung und Berichtigung
            </h5>{' '}
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
              Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten
              k&ouml;nnen Sie sich jederzeit an uns wenden.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Recht auf Einschr&auml;nkung der Verarbeitung
            </h5>{' '}
            <p>
              Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
              jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
              Verarbeitung besteht in folgenden F&auml;llen:
            </p>{' '}
            <ul className="list-inside list-disc">
              {' '}
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
                der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt
                der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
                verlangen.
              </li>{' '}
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
                Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
                Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                Interessen vorgenommen werden. Solange noch nicht feststeht,
                wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                Daten zu verlangen.
              </li>{' '}
            </ul>{' '}
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
              ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
              oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
              nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
              eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen
              Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              SSL- bzw. TLS-Verschl&uuml;sselung
            </h5>{' '}
            <p>
              Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
              &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
              Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
              senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
              verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
              Adresszeile des Browsers von &bdquo;http://&ldquo; auf
              &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>{' '}
            <p>
              Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
              k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von
              Dritten mitgelesen werden.
            </p>
            <h4 className="my-2 text-lg font-medium text-brand-brown">
              4. Datenerfassung auf dieser Website
            </h4>
            <h5 className="my-2 font-medium text-brand-brown">
              Server-Log-Dateien
            </h5>{' '}
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns &uuml;bermittelt. Dies sind:
            </p>{' '}
            <ul className="list-inside list-disc">
              {' '}
              <li>Browsertyp und Browserversion</li>{' '}
              <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
              <li>Hostname des zugreifenden Rechners</li>{' '}
              <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
            </ul>{' '}
            <p>
              Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
              wird nicht vorgenommen.
            </p>{' '}
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files
              erfasst werden.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Kontaktformular
            </h5>{' '}
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und
              f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese
              Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{' '}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung
              zur Speicherung widerrufen oder der Zweck f&uuml;r die
              Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
              Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
              &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
              unber&uuml;hrt.
            </p>
            <h5 className="my-2 font-medium text-brand-brown">
              Anfrage per E-Mail, Telefon oder Telefax
            </h5>{' '}
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{' '}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
              verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
              die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
              abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
              gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
              Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
            </p>
          </Modal>
          {/*<Modal isInitiallyOpen={false} title={'AGB'}>*/}
          {/*  AGB Inhalt*/}
          {/*</Modal>*/}
        </div>
      </footer>

      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage {
    site {
      siteMetadata {
        title
        timeline
        galleries {
          key
          title
          teaser
        }
      }
    }
    teaserImage: file(relativePath: { eq: "teaser.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    contactImage: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    teamImages: allFile(
      filter: { relativeDirectory: { eq: "team" }, extension: { eq: "jpg" } }
      sort: { fields: [relativeDirectory, modifiedTime], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    galleryImages: allFile(
      filter: {
        relativeDirectory: { glob: "gallery/*" }
        extension: { eq: "jpg" }
      }
      sort: { fields: [relativeDirectory, modifiedTime], order: ASC }
    ) {
      nodes {
        relativeDirectory
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
