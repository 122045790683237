import * as React from 'react';

const Logo = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 35.1" {...props}>
    <style>{'.st0{fill:#ff990d}.st1{fill:#66e6d2}.st2{fill:#5a1e3c}'}</style>
    <path
      className="st0"
      d="M66.4 15.3H39.3V.2h27.1c4.2.2 7.5 3.7 7.3 7.9-.2 3.9-3.4 7.1-7.3 7.2M66.4 35.1H39.3V20h27.1c4.2.2 7.5 3.7 7.3 7.9-.2 3.9-3.4 7.1-7.3 7.2"
    />
    <path className="st1" d="M77.4 20L77.4 35.1 112 35.1 97.4 20z" />
    <path
      className="st1"
      d="M77.4 0.2L77.4 0.2 77.4 15.3 97.4 15.3 112 0.2 77.4 0.2z"
    />
    <path
      transform="matrix(1 -.00977 .00977 1 -.075 .17)"
      className="st2"
      d="M0.1 0.2H34.7V15.299999999999999H0.1z"
    />
    <path className="st2" d="M0.1 20H34.7V35.2H0.1z" />
  </svg>
);

export default Logo;
